import React, { useState } from 'react'
import './welcome.css'
//import '../layouts/layouts.css'
import { useTransition, animated } from "react-spring"
import Fade from 'react-reveal/Fade'
import iconUx from '../images/icon-UX.svg'
import iconVr from '../images/icon-VR.svg'
import iconStrat from '../images/icon-strategy.svg'
import iconStrat2 from '../images/icon-strategy-2.svg'
import iconArch from '../images/icon-arch.svg'
import iconUrban from '../images/icon-urban.svg'
import iconPDF from '../images/icon-PDF.svg'
import iconLinkedin from '../images/icon-linkedin.svg'

const About = ({ ...location }) => {

    const [isAboutVisible, setIsAboutVisible] = useState(true);

    const transition = useTransition(isAboutVisible, {
      from: { x: 0, y: 40, opacity: 0 },
      enter: { x: 0, y: 0, opacity: 1 },
      leave: { x: 0, y: 100, opacity: 0 },
      exitBeforeEnter: true,
    });

  return (
    <div>
      
      {/* <button className='front' onClick={() => {setIsWelcomeVisible(v => !v)}}>Mount welcome</button> */}
      <div>{transition((style, item) =>
        item ? <animated.div style={style}>
          <div className="about-section">
            <div className='about-intro'>
              <section id="titles" class="about-titles">
                <h1 className='about-title'>Sobre mi</h1>
                <Fade cascade ssrReveal={true} delay={200}>
                <h3 className='about-subtitle' style={{marginBottom: '0'}}>UX Designer | Arquitecto | React | UI | 3D | VR</h3>
                <h2 className='about-subtitle2'>Dirección de arte | Marketing | Diseño de producto</h2>
                </Fade>
              </section>
            <Fade delay={400}>
              <div className='about-text'>
                Puedo ayudar a conocer a las personas usuarias y sus necesidades,
                definir nuevos o existentes productos y servicios digitales gracias al
                design thinking y LEAN UX, construir prototipos interactivos y sistemas
                de diseño, y evaluarlos para comprobar el cumplimiento de los
                objetivos y las posibilidades de mejora.</div>
            </Fade>
            <Fade ssrReveal={false} delay={600} distance={'1rem'} duration={2000}>
              <a href='https://pablohumanes.es/wordpress/wp-content/uploads/2022/06/humanespablo_CV2022_ENG.pdf' download='humanespablo_CV2022_ENG.pdf' target="_blank" className='button-secondary' style={{margin: '2rem 1rem 2rem 0'}}>
                <img src={iconPDF} className='icon-button'/>
                Descarga mi CV</a>
              <a href='https://www.linkedin.com/in/pblhmns' target="_blank" className='button-secondary'>
                <img src={iconLinkedin} className='icon-button'/>
                Visita mi perfil</a>
            </Fade>
            </div>
            <h2 className='edu-bar-title1'>Educacion:</h2>
                <Fade bottom delay={200} distance={'3rem'} ssrReveal={false}>
                  <div className='uni-first'>
                    <div className='education-title'>Interacción y Experiencia de Usuario</div>
                    <div className='education-uni'>Universitat Oberta de Catalunya 🇪🇸</div>
                  </div>
                  <div className='icon-first'>
                    <img src={iconVr} className='icon-uni'></img>
                    <img src={iconUx} className='icon-uni'></img>
                  </div>
                </Fade>
                <Fade bottom delay={400} distance={'3rem'} ssrReveal={false}>
                  <div className='uni-second'>
                    <div className='education-title'>Estrategia e Identidad de Marca</div>
                    <div className='education-uni' style={{marginLeft: '1rem', textAlign: 'right'}}>Cologne University of Applied Sciences 🇩🇪</div>
                    <div className='education-uni'>Universidad Austral 🇦🇷</div>
                  </div>
                  <div className='icon-second'>
                    <img src={iconStrat} className='icon-uni'></img>
                    <img src={iconStrat2} className='icon-uni'></img>
                  </div>
                </Fade>
                <Fade bottom delay={600} distance={'3rem'} ssrReveal={false}>
                  <div className='uni-third'>
                    <div className='education-title'>Arquitectura, Diseño y Urbanismo</div>
                    <div className='education-uni'>Universidad de Alcalá 🇪🇸</div>
                  </div>
                  <div className='icon-third'>
                    <img src={iconArch} className='icon-uni'></img>
                    <img src={iconUrban} className='icon-uni'></img>
                  </div>
                </Fade>
            <div style={{height: '6rem'}} />
            {/* <div className='edu-bar-title2'>
              <Fade ssrReveal={true} delay={600} distance={'1rem'} duration={2000}>
                <div className='button-secondary' style={{margin: '2rem 1rem 2rem 0'}}>
                  Descarga mi CV</div>
                <div className='button-secondary'>
                  Visita mi perfil</div>
              </Fade>
            </div>  */}
          </div>
          </animated.div> : ''
        )}</div>
    </div>
  )
}

export default About