import React from 'react'
import About from '../components/About'
import '../components/welcome.css'
import {Helmet} from "react-helmet"

const about = () => {
  return (
    <div>
    <Helmet>
        <title>Sobre mi | Pablo Humanes: Estrategia, Diseño y Arquitecura</title>
        <script>{`
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//pablohumanes.es/matomo/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
    </Helmet>
      <About />
    </div>
  )
}

export default about